<template>
  <div>
    <p class="text-2xl">
      Torpedo de Voz
    </p>
    <v-card>
      <v-form autocomplete="off">
        <validation-observer ref="formVoice">
          <v-card-text class="pa-2">
            <v-row dense>
              <v-col
                sm="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Tipo"
                  rules="required"
                  vid="type"
                >
                  <v-select
                    v-model="typeVoice"
                    :error-messages="errors"
                    :items="items.types"
                    dense
                    item-text="text"
                    item-value="value"
                    label="Tipo"
                    outlined
                  />
                </validation-provider>
              </v-col>
              <v-col
                sm="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Nome da Campanha"
                  rules="required"
                  vid="name"
                >
                  <v-text-field
                    v-model="name"
                    :error-messages="errors"
                    dense
                    label="Nome da Campanha"
                    outlined
                  />
                </validation-provider>
              </v-col>
              <v-col
                sm="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Serviço"
                  rules="required"
                  vid="serviceId"
                >
                  <v-select
                    v-model="serviceId"
                    :disabled="typeVoice === voiceType.Intelligent"
                    :error-messages="errors"
                    :items="items.services"
                    dense
                    item-text="text"
                    item-value="value"
                    label="Serviço"
                    outlined
                  />
                </validation-provider>
              </v-col>
              <v-col
                sm="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Data Envio"
                  rules="required|max:160"
                  vid="sendDate"
                >
                  <date
                    v-model="sendDate"
                    :error-messages="errors"
                    dense
                    label="Data Envio"
                    outlined
                    type="datetime"
                  />
                </validation-provider>
              </v-col>
              <v-col
                sm="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Data Fim"
                  :rules="rulesEndDate"
                  vid="endDate"
                >
                  <date
                    v-model="endDate"
                    :error-messages="errors"
                    dense
                    label="Data Fim"
                    outlined
                    type="datetime"
                  />
                </validation-provider>
              </v-col>
              <v-col
                sm="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Velocidade Discagem"
                  rules="required"
                  vid="dialSpeed"
                >
                  <v-select
                    v-model="dialSpeed"
                    :error-messages="errors"
                    :items="items.dialSpeeds"
                    dense
                    label="Velocidade Discagem"
                    outlined
                  />
                </validation-provider>
              </v-col>
              <v-col
                sm="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Tentativas"
                  rules="required"
                  vid="attempt"
                >
                  <v-select
                    v-model="attempt"
                    :error-messages="errors"
                    :items="items.attempts"
                    dense
                    label="Tentativas"
                    outlined
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-for="(audio, index) in audios"
                :key="index"
                sm="12"
                md="3"
              >
                <v-card
                  v-if="audio.types.includes(typeVoice)"
                  class="mx-auto"
                  outlined
                >
                  <v-card-title class="text-body-1">
                    {{ audio.title }}
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="audio.value"
                          :items="audiosFromServer"
                          :prepend-inner-icon="icons.mdiPaperclip"
                          dense
                          hide-details
                          item-text="fileName"
                          item-value="id"
                          label="Áudio"
                          outlined
                          @change="audioPreview(audio.value, index)"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content v-if="item.id < 0">
                              <v-list-item-title class="accent--text font-weight-bold">
                                {{ item.fileName }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content v-else>
                              <v-list-item-title>{{ item.fileName }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col
                        v-if="audio.previewUrl"
                        cols="12"
                      >
                        <v-card
                          class="mx-auto"
                          outlined
                        >
                          <v-card-text class="pa-2 pt-3">
                            <audio
                              style="width: 100%"
                              controls
                            >
                              <source
                                :src="audio.previewUrl"
                                type="audio/mpeg"
                              />
                            </audio>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-if="typeVoice === voiceType.Simple"
                sm="12"
                md="6"
              >
                <v-card
                  class="mx-auto"
                  outlined
                >
                  <v-card-title class="text-body-1">
                    Enviar SMS
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-select
                          v-model="uraSimpleSendSms"
                          :items="uraSimpleSendSmsItems"
                          dense
                          hide-details
                          item-text="text"
                          item-value="value"
                          label="Deseja enviar SMS?"
                          outlined
                          @change="uraSimpleSendSms === uraSimpleSendSmsEnum.No ? uraSimpleSmsTemplateId = null : null"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="uraSimpleSmsTemplateId"
                          :items="smsTemplatesFromServer"
                          :prepend-inner-icon="icons.mdiMessageText"
                          :disabled="[uraSimpleSendSmsEnum.No].includes(uraSimpleSendSms)"
                          dense
                          hide-details
                          item-text="name"
                          item-value="id"
                          label="SMS Template"
                          outlined
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item-title>
                                    <v-icon
                                      color="primary"
                                      class="me-1"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                    {{ item.name }}
                                  </v-list-item-title>
                                </template>
                                <span>{{ item.message }}</span>
                              </v-tooltip>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mb-2">
              <v-col cols="3">
                <v-card
                  class="mx-auto"
                  outlined
                >
                  <v-card-text>
                    <v-textarea
                      v-model="phonesTextarea"
                      label="Telefones (DDD + 9º dígito + número)"
                      outlined
                      dense
                      hide-details
                      placeholder="11999999991
11999999992
11999999993
11999999994"
                      @input="processFileContent(phonesTextarea)"
                    />

                    <v-divider class="my-3" />

                    <input
                      ref="phoneFile"
                      type="file"
                      accept=".txt"
                      :hidden="true"
                      @change="getFile"
                    />

                    <v-btn
                      class="me-3"
                      color="primary"
                      @click="$refs.phoneFile.click()"
                    >
                      <v-icon class="d-sm-none">
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                      <span class="d-none d-sm-block">Subir Contatos</span>
                    </v-btn>

                    <v-btn
                      color="error"
                      outlined
                      @click="resetPhones"
                    >
                      Resetar
                    </v-btn>

                    <br />

                    <div class="mt-2">
                      Cole a lista de telefones ou selecione um arquivo <code>(.txt)</code>.
                    </div>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <p class="mb-0">
                      Números no arquivo: <b class="primary--text">{{ totalPhones }}</b> <br />
                      Duplicados a serem removidos:
                      <b class="primary--text">{{ duplicatePhones }}</b> <br />
                      Total geral de números: <b class="primary--text">{{ phones.length }}</b>
                    </p>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col
                v-if="typeVoice === voiceType.Intelligent"
                cols="9"
              >
                <v-card
                  class="mx-auto"
                  outlined
                >
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="text-center"
                            width="10%"
                          >
                            Dígito
                          </th>
                          <th
                            class="text-left"
                            width="20%"
                          >
                            Ação
                          </th>
                          <th
                            class="text-center"
                            width="20%"
                          >
                            Audio
                          </th>
                          <th
                            class="text-center"
                            width="20%"
                          >
                            Webhook
                          </th>
                          <th
                            class="text-center"
                            width="20%"
                          >
                            Template SMS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(ura, index) in uras"
                          :key="index"
                        >
                          <td class="text-center">
                            <v-avatar
                              color="secondary"
                              size="30"
                              tile
                            >
                              <span class="primary--text text-h6">{{ ura.option }}</span>
                            </v-avatar>
                          </td>
                          <td>
                            <v-select
                              v-model="ura.action"
                              :items="uraActions"
                              hide-details="false"
                              item-text="value"
                              item-value="id"
                              dense
                              label="Selecione..."
                              solo
                            />
                          </td>
                          <td
                            class="text-center"
                          >
                            <v-select
                              v-if="ura.action && ![uraAction.sendSms, uraAction.endCall].includes(ura.action)"
                              v-model="ura.audioId"
                              :items="audiosFromServer"
                              :prepend-inner-icon="icons.mdiPaperclip"
                              dense
                              hide-details
                              item-text="fileName"
                              item-value="id"
                              label="Áudio"
                              solo
                            >
                              <template v-slot:item="{ item }">
                                <v-list-item-content
                                  v-if="item.id < 0"
                                  @click="dialogUploadAudio = true"
                                >
                                  <v-list-item-title class="accent--text font-weight-bold">
                                    {{ item.fileName }}
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content v-else>
                                  <v-list-item-title>{{ item.fileName }}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </td>
                          <td
                            class="text-center"
                          >
                            <v-select
                              v-if="ura.action && ura.action === uraAction.playAudioSendRequest"
                              v-model="ura.webhookId"
                              :items="webhooksFromServer"
                              :prepend-inner-icon="icons.mdiApi"
                              dense
                              hide-details
                              item-text="name"
                              item-value="id"
                              label="Webhook"
                              solo
                            >
                              <template v-slot:item="{ item }">
                                <v-list-item-content
                                  v-if="item.id < 0"
                                  @click="dialogWebhook = true"
                                >
                                  <v-list-item-title class="accent--text font-weight-bold">
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content v-else>
                                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </td>
                          <td
                            class="text-center"
                          >
                            <v-select
                              v-if="ura.action && [uraAction.sendSms, uraAction.playAudioSendSms].includes(ura.action)"
                              v-model="ura.smsTemplateId"
                              :items="smsTemplatesFromServer"
                              :prepend-inner-icon="icons.mdiMessageText"
                              dense
                              hide-details
                              item-text="name"
                              item-value="id"
                              label="SMS Template"
                              solo
                            >
                              <template v-slot:item="{ item }">
                                <v-list-item-content
                                  v-if="item.id < 0"
                                  @click="dialogSmsTemplate = true"
                                >
                                  <v-list-item-title class="accent--text font-weight-bold">
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content v-else>
                                  <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-list-item-title>
                                        <v-icon
                                          color="primary"
                                          class="me-1"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          {{ icons.mdiInformationOutline }}
                                        </v-icon>
                                        {{ item.name }}
                                      </v-list-item-title>
                                    </template>
                                    <span>{{ item.message }}</span>
                                  </v-tooltip>
                                </v-list-item-content>
                              </template>
                            </v-select>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-text class="pa-2">
            <v-btn
              color="primary"
              class="me-3 mt-3"
              @click="checkAccountConfirmation = true"
            >
              Salvar
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-3"
              @click="resetForm"
            >
              Limpar
            </v-btn>
          </v-card-text>
        </validation-observer>
      </v-form>
    </v-card>

    <validation-observer
      ref="formAudioUpload"
      autocomplete="off"
      tag="form"
    >
      <dialog-form
        :status="dialogUploadAudio"
        :is-update="false"
        :title="'Upload'"
        :width="500"
        @action="uploadAudio"
        @close="dialogUploadAudio = !dialogUploadAudio"
      >
        <v-row dense>
          <v-col
            cols="12"
            md="12"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Arquivo Áudio"
              rules="required"
              vid="audioFile"
            >
              <v-file-input
                v-model="audioFile"
                :error-messages="errors"
                :prepend-icon="''"
                :prepend-inner-icon="icons.mdiPaperclip"
                :rules="rulesAudio"
                hide-details="auto"
                accept="audio/mpeg"
                dense
                label="Arquivo Áudio"
                show-size
                outlined
                truncate-length="50"
              />
            </validation-provider>
          </v-col>
          <v-col
            v-if="audioFileUrl"
            cols="12"
          >
            <v-card
              class="mx-auto"
              outlined
            >
              <v-card-text class="pa-2 pb-1">
                <audio
                  style="width: 100%"
                  controls
                >
                  <source
                    :src="audioFileUrl"
                    type="audio/mpeg"
                  />
                </audio>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </dialog-form>
    </validation-observer>

    <webhook
      :status="dialogWebhook"
      @close-dialog="dialogWebhook = false"
      @find="fetchWebhooks"
    />

    <sms-template
      :status="dialogSmsTemplate"
      @close-dialog="dialogSmsTemplate = false"
      @find="fetchSmsTemplates"
    />

    <v-dialog
      v-model="dialogSimpleUraAlert"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Alerta envio SMS
        </v-card-title>

        <v-card-text>
          Uma nova campanha para envios de SMS será criada. O custo da campanha será cobrado separadamente.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="dialogSimpleUraAlert = false"
          >
            Ok, entendi!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-account
      v-if="checkAccountConfirmation"
      @accountStatus="checkConfirmedAccount"
      @finish="store"
    />
    <tutorial tutorial-key="TSP4" />
  </div>
</template>

<script>
import 'dayjs/locale/en'

import { mapActions } from 'vuex'
import { ref } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'
import {
  mdiApi,
  mdiCloudUploadOutline,
  mdiMessageText,
  mdiPaperclip,
  mdiPlayCircle,
  mdiInformationOutline,
} from '@mdi/js'
import {
  voiceType,
  voiceTypes,
  voiceDialSpeeds,
  voiceServices,
  serviceType,
  uraActions,
  uraAction,
  uraOptions,
  uraSimpleSendSmsEnum,
  uraSimpleSendSmsItems,
  smsTemplate as smsTemplateEnum,
} from '@/utils/enum'
import { getTotalPhones, groupByArrayAndRemoveDuplicates } from '@/utils/filePhones'
import Webhook from '@/views/serviceVoice/webhook.vue'
import SmsTemplate from '@/views/serviceVoice/smsTemplate.vue'
import ConfirmAccount from '@/components/confirm-acount/index.vue'
import Tutorial from '@/components/tutorial/index.vue'

export default {
  components: {
    SmsTemplate,
    Webhook,
    ConfirmAccount,
    Tutorial,
  },
  computed: {
    rulesEndDate() {
      return 'required|max:160'
    },

    rulesAudio() {
      return [
        value => !value || value.size < 4000000 || 'Arquivo de áudio deve ser menor que 4 MB.',
      ]
    },
  },
  setup() {
    const audioFile = ref(null)
    const audioFileUrl = ref(null)
    const dialogUploadAudio = ref(false)
    const typeVoice = ref(2) // URA Inteligente
    const name = ref(null)
    const serviceId = ref(serviceType.Voice60s)
    const sendDate = ref(null)
    const endDate = ref(null)
    const dialSpeed = ref(3) // Rápida
    const attempt = ref(3) // 3 Tentativas
    const uraSimpleSendSms = ref(0) // Não
    const uraSimpleSmsTemplateId = ref(null)
    const dialogSimpleUraAlert = ref(false)

    const phones = ref([])
    const phonesTextarea = ref('')
    const duplicatePhones = ref(0)
    const totalPhones = ref(0)

    const audiosFromServer = ref([])
    const audios = ref([
      {
        title: 'Áudio Principal',
        previewUrl: null,
        value: null,
        types: [voiceType.Simple, voiceType.Intelligent],
      },
    ])

    const urasAux = []
    uraOptions.forEach(option => {
      urasAux.push({
        option,
        action: null,
        audioId: null,
        smsTemplateId: null,
        webhookId: null,
      })
    })

    const uras = ref(urasAux)
    const webhooksFromServer = ref([])
    const dialogWebhook = ref(false)
    const smsTemplatesFromServer = ref([])
    const dialogSmsTemplate = ref(false)

    const checkAccountConfirmation = ref(false)

    return {
      audioFile,
      audioFileUrl,
      dialogUploadAudio,
      typeVoice,
      name,
      serviceId,
      sendDate,
      endDate,
      dialSpeed,
      attempt,
      uraSimpleSendSms,
      uraSimpleSmsTemplateId,
      uraSimpleSendSmsEnum,
      uraSimpleSendSmsItems,
      dialogSimpleUraAlert,
      phones,
      phonesTextarea,
      duplicatePhones,
      totalPhones,
      audiosFromServer,
      audios,
      webhooksFromServer,
      dialogWebhook,
      smsTemplatesFromServer,
      dialogSmsTemplate,
      icons: {
        mdiApi,
        mdiCloudUploadOutline,
        mdiMessageText,
        mdiPaperclip,
        mdiPlayCircle,
        mdiInformationOutline,
      },
      items: {
        attempts: [1, 2, 3],
        dialSpeeds: voiceDialSpeeds,
        services: voiceServices,
        types: voiceTypes,
      },
      voiceType,
      uraActions,
      uraAction,
      uras,
      checkAccountConfirmation,
    }
  },

  watch: {
    audioFile(value) {
      this.audioFileUrl = value ? URL.createObjectURL(value) : null
    },

    typeVoice(value) {
      if (value === voiceType.Intelligent) this.serviceId = serviceType.Voice60s
      else this.serviceId = null
    },

    uraSimpleSendSms(value) {
      if ([uraSimpleSendSmsEnum.AllNumbers, uraSimpleSendSmsEnum.NumbersServed].includes(value)) {
        this.dialogSimpleUraAlert = true
      }
    },
  },

  async created() {
    await this.fetchAudios()
    await this.fetchSmsTemplates()
    await this.fetchWebhooks()

    this.sendDate = this.$day().format('DD/MM/YYYY HH:mm')
    this.endDate = this.$day().add(1, 'day').format('DD/MM/YYYY HH:mm')
  },

  methods: {
    ...mapActions('controlFile', {
      getAudios: 'find',
      getAudio: 'findById',
      upload: 'create',
    }),
    ...mapActions('serviceVoice', ['create']),
    ...mapActions('smsTemplates', { getSmsTemplates: 'find' }),
    ...mapActions('webhook', { getWebhooks: 'find' }),

    formatDate(date) {
      return this.$day(date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')
    },

    resetPhones() {
      this.$refs.phoneFile.value = null
      this.phones = []
      this.duplicatePhones = 0
      this.totalPhones = 0
    },

    resetForm() {
      this.dialogUploadAudio = false
      this.typeVoice = 2 // URA Inteligente
      this.name = null
      this.serviceId = null
      this.sendDate = null
      this.endDate = null
      this.dialSpeed = 3 // Rápida
      this.attempt = 3 // 3 Tentativas
      this.uraSimpleSendSms = 0 // Não
      this.uraSimpleSmsTemplateId = null
      this.phonesTextarea = ''
      this.audios = [
        {
          title: 'Áudio Principal',
          previewUrl: null,
          value: null,
          types: [voiceType.Simple, voiceType.Intelligent],
        },
      ]

      this.resetPhones()
    },

    generateFileUrl(file) {
      return URL.createObjectURL(file)
    },

    audioPreview(audioId, index) {
      if (audioId === -1) {
        this.dialogUploadAudio = true

        return
      }

      this.getAudio(audioId).then(res => {
        const uint8Array = new Uint8Array(res.data)
        const blob = new Blob([uint8Array], { type: 'audio/mpeg' })

        this.$set(this.audios[index], 'previewUrl', URL.createObjectURL(blob))
      })
    },

    async fetchAudios() {
      const audios = await this.getAudios()

      this.audiosFromServer = [
        { id: -1, fileName: 'Cadastrar áudio...' },
        ...audios,
      ]
    },

    async fetchWebhooks() {
      const webhooks = await this.getWebhooks()

      this.webhooksFromServer = [
        { id: -1, name: 'Cadastrar webhook...' },
        ...webhooks,
      ]
    },

    async fetchSmsTemplates() {
      this.smsTemplatesFromServer = await this.getSmsTemplates({
        status: smsTemplateEnum.Approved,
      })
    },

    async processFileContent(rows) {
      const totalPhones = getTotalPhones(rows)
      const phones = groupByArrayAndRemoveDuplicates(totalPhones)
      const duplicatePhones = totalPhones.length - phones.length

      this.phones = phones
      this.duplicatePhones = duplicatePhones
      this.totalPhones = totalPhones.length
    },

    async validateFile(file) {
      if (!file) {
        this.$notify('Arquivo não importado.', 'error')

        return false
      }

      if (file.type !== 'text/plain') {
        this.$notify('Selecione um arquivo texto (.txt).', 'error')

        return false
      }

      return true
    },

    async readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => resolve(e.target.result)
        reader.onerror = error => reject(error)
        reader.readAsText(file)
      })
    },

    async getFile(e) {
      const file = e.target.files ? e.target.files[0] : null

      if (!this.validateFile(file)) return

      try {
        const content = await this.readFile(file)
        this.processFileContent(content)
      } catch (error) {
        this.$notify('Erro ao ler o arquivo.', 'error')
      }
    },

    async validateForm() {
      if (!await this.$refs.formVoice.validate()) return false

      if (!this.phonesTextarea && this.phones.length === 0) {
        this.$notify('Informe uma lista de números ou selecione um arquivo (.txt).', 'error')

        return false
      }

      if (!this.audios[0].value) {
        this.$notify('Por favor selecione o áudio principal.', 'error')

        return false
      }

      if (this.typeVoice === voiceType.Simple) {
        if ([uraSimpleSendSmsEnum.AllNumbers, uraSimpleSendSmsEnum.NumbersServed].includes(this.uraSimpleSendSms) && !this.uraSimpleSmsTemplateId) {
          this.$notify('Selecione o SMS template.', 'error')

          return false
        }
      }

      return true
    },

    formatUraActions() {
      const actions = []
      const uras = this.uras.filter(ura => ura.action !== null)

      uras.forEach(ura => {
        const action = {
          digit: ura.option,
          uraActionId: ura.action,
        }

        const addAudioConfiguration = () => {
          if (!ura.audioId) {
            throw new Error(`Selecione o áudio do dígito ${ura.option}.`)
          }
          action.configuration = { ...action.configuration, audioFileId: ura.audioId }

          return true
        }

        if ([uraAction.playAudio, uraAction.playAudioSendRequest, uraAction.playAudioSendSms].includes(ura.action)) {
          if (!addAudioConfiguration()) return
        }

        if (ura.action === uraAction.playAudioSendRequest) {
          if (!ura.webhookId) {
            throw new Error(`Selecione o webhook do dígito ${ura.option}.`)
          }
          action.configuration = { ...action.configuration, webhookId: ura.webhookId }
        }

        if ([uraAction.playAudioSendSms, uraAction.sendSms].includes(ura.action)) {
          if (!ura.smsTemplateId) {
            throw new Error(`Selecione o SMS template do dígito ${ura.option}.`)
          }
          action.configuration = { ...action.configuration, smsTemplateId: ura.smsTemplateId, smsServiceId: 1 }
        }

        actions.push(action)
      })

      return actions
    },

    async checkConfirmedAccount(status) {
      if (status === true) {
        await this.store()
      }
    },

    async store() {
      this.checkAccountConfirmation = false

      if (!await this.validateForm()) return

      const body = {
        name: this.name,
        sendDate: this.formatDate(this.sendDate),
        endDate: this.formatDate(this.endDate),
        serviceId: this.serviceId,
        typeVoice: Number(this.typeVoice),
        dialSpeed: this.dialSpeed,
        attempt: this.attempt,
        intervalAttempt: 5,
        phones: this.phones,
        simpleUraSendSms: this.uraSimpleSendSms,
        simpleUraSmsTemplateId: this.uraSimpleSmsTemplateId,
        simpleUraSmsServiceId: 1, // Token
      }

      if (this.typeVoice === voiceType.Intelligent) {
        let actions = []

        try {
          actions = this.formatUraActions()
        } catch (error) {
          this.$notify(error.message, 'error')

          return
        }

        body.uras = [
          {
            name: 'default',
            code: uuidv4(),
            audioMainId: this.audios[0].value,
            audioMainRepetitions: 1,
            audioInvalidDigitEnteredId: 0,
            actions,
          },
        ]
      }

      if (this.typeVoice === voiceType.Simple) {
        body.audioFileId = this.audios[0].value
      }

      const status = await this.create(body)

      if (status === 201) {
        this.$router.push('/report/service')
        this.resetForm()
        this.resetPhones()
      }
    },

    async uploadAudio() {
      if (await this.$refs.formAudioUpload.validate()) {
        if (this.audioFile.size > 4000000) {
          this.$notify('Arquivo de áudio deve ser menor que 4 MB.', 'error')

          return
        }

        const fileId = await this.upload(this.audioFile)

        if (fileId) {
          this.dialogUploadAudio = false
          this.audioFileUrl = null
          this.audioFile = null

          await this.fetchAudios()
        }
      }
    },
  },
}
</script>
